import { accumulateLaboratoryValuesWithUnit, isAcceptableValue } from "./mapperUtils";

import { MCV, RDW } from "domain/Laboratory.model";

export function missingHematoAnswers({ patientData, mutationData, laboratoryData }) {
    const laboratoryWithUnit = accumulateLaboratoryValuesWithUnit(laboratoryData);
    const lab = Object.keys(laboratoryWithUnit)
        .filter((key) => [MCV, RDW].includes(key))
        .reduce(
            (accumulator, key) => ({
                ...accumulator,
                [key]: laboratoryWithUnit[key]?.value,
            }),
            {},
        );
    const answerMap = {
        "my-risk.missing-mcv": lab.mcv,
        "my-risk.missing-rdw": lab.rdw,
        "my-risk.missing-chip": mutationData?.length,
        "my-risk.missing-birthday": patientData.dateOfBirth,
    };

    return Object.keys(answerMap).reduce((accumulator, current) => {
        if (isAcceptableValue(answerMap[current])) {
            return accumulator;
        } else {
            return [...accumulator, current];
        }
    }, []);
}
