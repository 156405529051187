import { Pie, Cell, PieChart, Legend } from "recharts";
import { useTranslation } from "react-i18next";
import { missingHematoAnswers } from "./calculators/HematologyRiskProfile.mapper";
import { Paper, Typography } from "@material-ui/core";
import Flex from "components/grid/Flex";

const ScoreType = {
    LOW: "low",
    INTERMEDIATE: "intermediate",
    HIGH: "high",
};

export const riskColor = {
    low: "#FFD760",
    intermediate: "#FFA013",
    high: "#AC0000",
};

const CHRSscoreTypeToTranslationString = (scoreType) => {
    return scoreType ? ["my-risk", "score-label", scoreType].join(".") : 'clinic.not-available';
};

function RiskPieChart({ scoreColor, scoreLabel, size, riskPercentage = 0 }) {
    return (
        <PieChart width={size} height={size}>
            <text
                x={"50%"}
                y={"50%"}
                fill={scoreColor}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    fontSize: size / (scoreLabel.length >= 10 ? 10 : scoreLabel.length >= 7 ? 6 : 4),
                    fontWeight: "bold",
                }}
            >
                {scoreLabel}
            </text>
            <Pie
                dataKey="percent"
                isAnimationActive={false}
                innerRadius="80%"
                outerRadius="100%"
                startAngle={-270}
                paddingAngle={0}
                data={[{ percent: 100 - riskPercentage }, { percent: riskPercentage }]}
            >
                <Cell id={"empty"} fill={"#f2f2f2"} />
                <Cell id={"fullRiskPercent"} fill={scoreColor} />
            </Pie>
        </PieChart>
    );
}

export default function HematologyRisk({ riskData, patientData, mutationData, laboratoryData }) {
    const { t } = useTranslation();
    const { hematologyScoreType, hematologyRiskPercentage } = riskData;
    const missingAnswers = missingHematoAnswers({ patientData, mutationData, laboratoryData });
    const scoreTitle =
        hematologyScoreType === ScoreType.LOW
            ? t("my-risk.title-LOW")
            : hematologyScoreType === ScoreType.INTERMEDIATE
              ? t("my-risk.title-INTERMEDIATE")
              : t("my-risk.title-HIGH");
    const scoreColor = riskColor[hematologyScoreType];
    const isAnswersMissing = missingAnswers.length > 0;
    const scoreLabel = t(CHRSscoreTypeToTranslationString(hematologyScoreType));

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Typography style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}>
                {t("my-risk.hematology")}
            </Typography>
            <RiskPieChart {...{ size: 340, scoreColor, scoreLabel, riskPercentage: hematologyRiskPercentage }} />
            <Flex container item center>
                <Typography style={{ fontSize: 12 }}>Clonal Hematopoiesis Risk Score (CHRS)</Typography>
            </Flex>
            <Typography variant={"h6"} style={{ margin: "20px 0px" }}>
                {scoreTitle}
            </Typography>
            {isAnswersMissing && (
                <Typography variant={"h6"} style={{ margin: "20px 0px" }}>
                    {t("my-risk.missing-answers")}
                </Typography>
            )}
            {!!missingAnswers.length && (
                <Paper style={{ padding: 10, minWidth: "100%" }} elevation={4}>
                    {missingAnswers.filter((answer) => ["my-risk.missing-mcv", "my-risk.missing-rdw"].includes(answer))
                        .length > 0 && (
                        <>
                            <Typography style={{ margin: "5px 0px", fontWeight: "bold", fontSize: 16 }}>
                                {t("patient.laboratory-history")}
                            </Typography>
                            <ul>
                                {missingAnswers.includes("my-risk.missing-mcv") && (
                                    <li>
                                        <Typography style={{ fontWeight: "bold" }}>
                                            {t("my-risk.missing-mcv")}
                                        </Typography>
                                    </li>
                                )}
                                {missingAnswers.includes("my-risk.missing-rdw") && (
                                    <li>
                                        <Typography style={{ fontWeight: "bold" }}>
                                            {t("my-risk.missing-rdw")}
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </>
                    )}
                    {missingAnswers.includes("my-risk.missing-chip") && (
                        <>
                            <Typography style={{ margin: "5px 0px", fontWeight: "bold", fontSize: 16 }}>
                                {t("my-risk.chip-mutation")}
                            </Typography>
                            <ul>
                                <li>
                                    <Typography style={{ fontWeight: "bold" }}>{t("my-risk.missing-chip")}</Typography>
                                </li>
                            </ul>
                        </>
                    )}
                    {missingAnswers.includes("my-risk.missing-birthday") && (
                        <>
                            <Typography style={{ margin: "5px 0px", fontWeight: "bold", fontSize: 16 }}>
                                {t("my-risk.missing-birthday")}
                            </Typography>
                            <ul>
                                <li>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        {t("my-risk.missing-birthday")}
                                    </Typography>
                                </li>
                            </ul>
                        </>
                    )}
                </Paper>
            )}
        </div>
    );
}
