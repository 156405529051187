import { Tooltip } from "@material-ui/core";
import React from "react";

const RiskCircle = ({ color, style, title }) => {
    return (
        <Tooltip title={title}>
            <div
                style={{
                    alignSelf: 'center',
                    marginLeft: 5,
                    backgroundColor: color || "rgb(112,48,160)",
                    borderRadius: "50%",
                    maxHeight: 18,
                    minHeight: 18,
                    minWidth: 18,
                    ...style,
                }}
            ></div>
        </Tooltip>
    );
};

export default RiskCircle;
