import {
    register,
    getEmailForToken,
    downloadPrivacyPolicy,
    downloadDeclarationOfParticipation,
    listCareProvidersForRegistration,
    requestRegistrationTokenForEmail,
} from './Registration.action';
import {silentLogout, logout} from 'scenes/Main.action';

export default {
    mapStateToProps: state => {
        return {}
    },
    mapDispatchToProps: dispatch => {
        return {
            register: props => dispatch(register(props)),
            silentLogout: () => dispatch(silentLogout()),
            logout: () => dispatch(logout()),
            downloadPrivacyPolicy: (careProviderId) => dispatch(downloadPrivacyPolicy(careProviderId)),
            downloadDeclarationOfParticipation: (careProviderId) => dispatch(downloadDeclarationOfParticipation(careProviderId)),
            getEmailForToken: inviteToken => dispatch(getEmailForToken(inviteToken)),
            listCareProvidersForRegistration: () => dispatch(listCareProvidersForRegistration()),
            requestTokenForEmail: (email, careProviderId) => dispatch(requestRegistrationTokenForEmail(email, careProviderId)),
        }
    }
}
