import {useTranslation} from "react-i18next";
import {useState} from "react";
import update from "immutability-helper";
import MultiInput from 'components/input/MultiInput';
import EmailAddressEditorDialog from 'scenes/user-manager/access-manager/EmailAddressEditorDialog';
import {EDIT} from 'domain/GrantType';
import utils from "utils/Utils";

const MultiInputEmailAddressEditor = props => {
    const {
        emailAddresses, invitedRoleType, disabled, defaultTextFieldProps, standAlone, updateInputValues, person,
        refresh, entityIdToAssign, entityType, scenarioOverride
    } = props;
    const {t: translate} = useTranslation();
    const [originalEmailIndex, setOriginalEmailIndex] = useState('');

    const closeEmailDialog = () => {
        setOriginalEmailIndex('');
    }

    const onEmailChange = emailAddress => {
        updateInputValues({
            emailAddresses: update(emailAddresses, {
                [originalEmailIndex]: {$set: emailAddress}
            })
        });
        closeEmailDialog();
    }

    const deleteEmail = () => {
        updateInputValues({
            emailAddresses: update(emailAddresses, {
                $splice: [[originalEmailIndex, 1]]
            })
        });
        closeEmailDialog();
    }

    const copyToClipboard = (value) => {
        utils.copyToClipboard(value);
    }

    return <>
        <MultiInput
                updateInputValues={updateInputValues}
                disabledInput={person.userEmailAddress}
                labelOverrideForFirstInput={person.userEmailAddress ? translate('global.user-email-address') : ''}
                standAlone={standAlone}
                translate={translate}
                isDisabled={disabled}
                name="emailAddresses"
                inputValues={emailAddresses}
                defaultTextFieldProps={defaultTextFieldProps}
                copyToClipboard={copyToClipboard}
                takeControlOnClick={(value, index) => {
                    setOriginalEmailIndex(index);
                }}
        />
        {
            originalEmailIndex !== '' && !disabled &&
            <EmailAddressEditorDialog {...{
                onEmailChange, deleteEmail, invitedRoleType, person, entityIdToAssign, scenarioOverride,
                entityType, refresh,
                editorType: EDIT,
                toggleOpen: closeEmailDialog,
                originalEmail: emailAddresses[originalEmailIndex]
            }}/>
        }
    </>
};

export default MultiInputEmailAddressEditor;
