import { accumulateLaboratoryValuesWithUnit, isAcceptableValue} from './mapperUtils';

export function missingAnswers(laboratoryData, anamnese){
    const laboratoryWithUnit = accumulateLaboratoryValuesWithUnit(laboratoryData);
    const lab = mapLaboratoryValues(laboratoryWithUnit);
    const answerMap = {
        "laboratory.timeline.totalcholesterol": lab.Cholesterol,
        "laboratory.timeline.hdlcholesterol": lab.HdlCholesterol,
        "laboratory.timeline.crp": lab.Inflammation,
        "laboratory.timeline.gfr": lab.Renal,
        "anamnesis.cardio.haveMiocardialHeartAttackQuestion": anamnese.haveMiocardialHeartAttack,
        "anamnesis.cardio.cardiovasclar-disase-question": anamnese.coronaryArteryDisease,
        "anamnesis.cardio.haveBypassSurgeryQuestion": anamnese.coronaryArteryDisease === true ? anamnese.haveBypassSurgery || anamnese.haveStentImplantation : anamnese.coronaryArteryDisease,
        "anamnesis.cardio.haveStentImplantationQuestion": anamnese.coronaryArteryDisease === true ? anamnese.haveBypassSurgery || anamnese.haveStentImplantation : anamnese.coronaryArteryDisease,
        "anamnesis.cardio.haveStrokeQuestion": anamnese.haveStroke,
        "anamnesis.cardio.haveStentImplantationOrSurgeryQuestion": anamnese.haveAtherosclerosis === true ? anamnese.haveAtherosclerosisStentImplantation : anamnese.haveAtherosclerosis,
        "anamnesis.cardio.hasEnlargementOfTheAortaQuestion": anamnese.hasEnlargementOfTheAorta,
        "anamnesis.cardio.haveAtherosclerosisLegArteriesQuestion": anamnese.haveAtherosclerosisLegArteries,
        "anamnesis.cardio.firstCardioDiseaseDateQuestion": anamnese.firstCardioDiseaseDate,
        "anamnesis.cardiovascular.smokeStatusQuestion": anamnese.smokeStatus,
        "anamnesis.cardiovascular.hasCardiovascularDiseaseQuestion": anamnese.hasCardiovascularDisease === true && anamnese.hasNoteBloodPressure === true
            ? anamnese.currentBloodPressure
            : anamnese.hasCardiovascularDisease === false && anamnese.hasNoteBloodPressure === false,
        "anamnesis.cardiovascular.hasNoteBloodPressureQuestion": anamnese.hasCardiovascularDisease === true && anamnese.hasNoteBloodPressure === true
            ? anamnese.currentBloodPressure
            : anamnese.hasCardiovascularDisease === false && anamnese.hasNoteBloodPressure === false,
        "anamnesis.cardiovascular.hasSufferingFromDiabetesQuestion": anamnese.hasSufferingFromDiabetes,
    }

    return Object.keys(answerMap).reduce((accumulator, current) => {
        if(isAcceptableValue(answerMap[current])){
            return accumulator;
        } else {
            return [...accumulator, current];
        }
    }, []);
}

function mapLaboratoryValues(lastLaboratory){
    return {
        Inflammation: mapToMgDl(lastLaboratory.crp?.value, lastLaboratory?.crp?.unit), //crp
        Cholesterol: mapToMgDl(lastLaboratory.totalcholesterol?.value, lastLaboratory.totalcholesterol?.unit),
        HdlCholesterol: mapToMgDl(lastLaboratory.hdlcholesterol?.value, lastLaboratory.hdlcholesterol?.unit),
        Renal: lastLaboratory.gfr?.value || 0
    }
}

function mapToMgDl(value, unit){
    switch (unit){
        case 'mgperdl': return value;
        case 'mgperl': return value * 10;
        default: return 0;
    }
}

